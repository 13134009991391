import Image from 'next/image'
import styled from 'styled-components'
import { shortHeaderHeight, tallHeaderHeight } from '../Header/Header.style'

export const FooterContainer = styled.footer<{ $withHeader: boolean }>`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  position: relative;
  top: ${shortHeaderHeight};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.regular};
  gap: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.regular};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.large}`};
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    top: ${({ $withHeader }) => ($withHeader ? tallHeaderHeight : 0)};
  }
`

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    gap: 2.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    gap: 4rem;
  }
`

export const GroupColumn = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
`

export const LogoImg = styled(Image)`
  height: 2rem;
  /* To make total height of the logo the same as the other column headings. */
  margin-bottom: 0.25rem;
`

export const AddressOrLegalInfoContainer = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  white-space: nowrap;
`

export const LegalInfo = styled.p`
  white-space: normal;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 14rem;
  }
`

export const AddressHeading = styled.h4`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
`

export const SocialLinkWrapper = styled.a`
  display: block;
  width: fit-content;
`

export const PrivacyNoticeLink = styled.a`
  color: ${({ theme }) => theme.colors.actionLink};
  font-size: 1rem;
  line-height: 1.5rem;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.colors.actionPrimary};
  }
`

export const ContactAndFollow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    gap: 2.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    gap: 4rem;
  }
`

export const SubHeading = styled.h3`
  font-family: 'Blacker', serif;
  font-size: 1.5rem;
  line-height: 2.25rem;
`

export const Email = styled.p`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.actionLink};
  font-size: 1rem;
  line-height: 1.5rem;
  white-space: nowrap;
`

export const ContactUsLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
