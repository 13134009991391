import styled from 'styled-components'

export const ProductFeatures = styled.section`
  text-align: center;
  padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.regular}`};
  background-color: ${({ theme }) => theme.colors.architecture4};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.large};
  }
`

export const InnerContainer = styled.div`
  display: block;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.maxWidth};
`

export const ProductFeaturesSubSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

export const SubSectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.medium};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-flow: row wrap;
    gap: 0;
  }
`

export const ProductFeatureArticle = styled.article<{ totalItems: number }>`
  display: flex;
  padding-top: 3rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 5rem;
    align-items: initial;
    text-align: left;
    width: min-content;
    gap: 0;
    margin-right: ${({ theme }) => theme.spacing.medium};
    flex: 1 1 20%;
    max-width: calc(50% - (${({ theme }) => theme.spacing.regular}) / 2);
  }
`

export const ProductFeatureArticleImage = styled.img`
  width: 3.125rem;
  height: 3.125rem;
`

export const ProductFeatureArticleLabel = styled.p`
  font-size: 1.3125rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: 0;
    margin-top: 2rem;
  }
`
